:root {
    font-family: Exo, sans-serif;
  }

@font-face {
  font-family: "Exo";
  src: url("../public/fonts/Exo-VariableFont.ttf") format("truetype");
}

html, body {
    height: 100%;
    margin: 0;
    overflow-x: hidden;
    background: #FCFAF6;
}

a {
  color: #000;
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}
